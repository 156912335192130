/*
Template Name: Admin Press Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
// Variables
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700');

$bodyfont:'Montserrat',
sans-serif;
$headingfont:'Montserrat',
sans-serif;

/*Theme Colors*/

$topbar: #1976d2;
$sidebar: #fff;
$sidebar-white: #e5edef;
$sidebar-alt:#edf0f5;
$bodycolor: #f4f6f9;
$headingtext: #455a64;
$bodytext: #67757c;
$sidebar-text: #687384;
$sidebar-icons: #555f6d;

$light-text: #a6b7bf;
$themecolor: #757780;
$themecolor-alt: #26c6da;
$themecolor-dark: #505258;

/*bootstrap Color*/
$danger: #ef5350;
$success: #06d79c;
$warning: #ffb22b;
$primary: #e30613;
$info: #398bf7;
$inverse: #2f3d4a;
$secondary: #cccccc;
$muted: #99abb4;
$dark: #263238;
$light: #e9edf2;
$extra-light: #ebf3f5;
$bglight:rgba(0, 0, 0, 0.02);

/*Light colors*/
$light-danger: #f9e7eb;
$light-success: #e8fdeb;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #cfecfe;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;



$danger-dark: #e6294b;
$success-dark: #04b381;
$warning-dark: #e9ab2e;
$primary-dark: #ca0511;
$info-dark: #028ee1;
$red-dark: #d61f1f;
$inverse-dark: #232a37;
$dark-transparent:rgba(0, 0, 0, 0.05);

$info-shadow: 0 2px 2px 0 rgba(66, 165, 245, .14),
0 3px 1px -2px rgba(66, 165, 245, .2),
0 1px 5px 0 rgba(66, 165, 245, .12);
$info-shadow-hover:0 14px 26px -12px rgba(23, 105, 255, .42),
0 4px 23px 0 rgba(0, 0, 0, .12),
0 8px 10px -5px rgba(23, 105, 255, .2);

$warning-shadow:0 2px 2px 0 rgba(248, 194, 0, .14),
0 3px 1px -2px rgba(248, 194, 0, .2),
0 1px 5px 0 rgba(248, 194, 0, .12);
$warning-shadow-hover:0 14px 26px -12px rgba(248, 194, 0, .42),
0 4px 23px 0 rgba(0, 0, 0, .12),
0 8px 10px -5px rgba(248, 194, 0, .2);

$danger-shadow:0 2px 2px 0 rgba(239, 83, 80, .14),
0 3px 1px -2px rgba(239, 83, 80, .2),
0 1px 5px 0 rgba(239, 83, 80, .12);
$danger-shadow-hover:0 14px 26px -12px rgba(239, 83, 80, .42),
0 4px 23px 0 rgba(0, 0, 0, .12),
0 8px 10px -5px rgba(239, 83, 80, .2);

$success-shadow:0 2px 2px 0 rgba(40, 190, 189, .14),
0 3px 1px -2px rgba(40, 190, 189, .2),
0 1px 5px 0 rgba(40, 190, 189, .12);
$success-shadow-hover:0 14px 26px -12px rgba(40, 190, 189, .42),
0 4px 23px 0 rgba(0, 0, 0, .12),
0 8px 10px -5px rgba(40, 190, 189, .2);

$primary-shadow:0 2px 2px 0 rgba(202, 5, 17, .14),
0 3px 1px -2px rgba(202, 5, 17, .2),
0 1px 5px 0 rgba(202, 5, 17, .12);
$primary-shadow-hover:0 14px 26px -12px rgba(202, 5, 17, .42),
0 4px 23px 0 rgba(0, 0, 0, .12),
0 8px 10px -5px rgba(202, 5, 17, .2);

$default-shadow:0 2px 2px 0 rgba(169, 169, 169, .14),
0 3px 1px -2px rgba(169, 169, 169, .2),
0 1px 5px 0 rgba(169, 169, 169, .12);
$default-shadow-hover:0 14px 26px -12px rgba(169, 169, 169, .42),
0 4px 23px 0 rgba(0, 0, 0, .12),
0 8px 10px -5px rgba(169, 169, 169, .2);

/*Normal Color*/
$white: #ffffff;
$red: #fb3a3a;
$yellow: #a0aec4;
$purple: #7460ee;
$blue: #02bec9;
$megna: #56c0d8;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$table-border:#f3f1f1;
$card-brd:#d7dfe3;
$dark-text: #848a96;
$radius: 4px;
$form-brd: #b1b8bb;

/*Preloader*/

.preloader {
  width: 100%;
  height: 100%;
  top: 0px;
  position: fixed;
  z-index: 99999;
  background: #fff;

  .cssload-speeding-wheel {
    position: absolute;
    top: calc(50% - 3.5px);
    left: calc(50% - 3.5px);
  }
}
