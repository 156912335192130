// Custom Theming for Angular Material
@use '@angular/material' as mat;
@use '@angular/material/theming' as mat-theming;
@import './variable.scss';
// For more information: https://material.angular.io/guide/theming

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$admin-angular-primary: mat.define-palette(mat.$blue-grey-palette);
$admin-angular-accent: mat.define-palette(mat.$blue-grey-palette);

$my-app-primary: mat.define-palette(mat.$blue-grey-palette);
$my-app-accent:  mat.define-palette(mat.$pink-palette, 500, 900, A100);
$my-app-warn:    mat.define-palette(mat.$deep-orange-palette);

// The warn palette is optional (defaults to red).
$admin-angular-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$admin-angular-theme: mat.define-light-theme((color: (
      primary: $admin-angular-primary,
      accent: $admin-angular-accent,
      warn: $admin-angular-warn,
    )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($admin-angular-theme);

/*
Template Name: Admin Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/

@import 'variable';
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import 'app';
@import 'pages';
@import 'sidebar';
@import '../scss/icons/font-awesome/css/font-awesome.min.css';
@import '../scss/icons/simple-line-icons/css/simple-line-icons.css';
@import '../scss/icons/themify-icons/themify-icons.css';
@import "../scss/icons/material-design-iconic-font/css/materialdesignicons.min.css";
@import 'widgets';
@import 'grid';
@import 'responsive';
@import 'spinners.scss';
@import 'theme-settings.scss';

// color css

@import 'colors/default-dark';

.mat-simple-snackbar-action {
  color: $primary;
}

input {
  display: block;
  width: 100%;
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #555;
  vertical-align: middle;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 15px;
}

.fa {
  padding-right: 5px;
}

a.name,
li .name {
  padding-right: 10px;
  color: inherit;
}

.list-flex {
  padding-left: 16px;
  margin: 10px 0;
}

.list-btn {
  margin-bottom: 10px;
}


a:nth-child(3n+1) .user-img .round {
  background-color: $primary;
}

a:nth-child(3n+2) .user-img .round {
  background-color: rgba(0, 21, 11, 54);
}

a:nth-child(3n+3) .user-img .round {
  background-color: rgba(0%, 63%, 59%, 18%);
}

a:nth-child(3n+4) .user-img .round {
  background-color: rgba(0%, 33%, 79%, 38%);
}

.mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex {
  padding: .75em .75em 0 .0em !important;
  background-color: transparent;
}
.mat-mdc-form-field {
  font-weight: 300;
}
.mat-radio-label-content, label.mat-checkbox-layout {
  padding-right: 14px !important;
}
.type {
  position: relative;
  display: inline-block;
  top: -1px;
}
// select {
//   -webkit-appearance: none;
// }

select.custom {
  -webkit-appearance: button;
  -moz-appearance: button;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-padding-end: 20px;
  -moz-padding-end: 20px;
  -webkit-padding-start: 2px;
  -moz-padding-start: 2px;
  background-color: #Fafafa; /* Fallback color if gradients are not supported */
  background-position: center right;
  background-repeat: no-repeat;
  border: 1px solid #AAA;
  border-radius: 12px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  color: #555;
  font-size: inherit;
  margin: 0;
  overflow: hidden;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 10px;
}
.mat-expansion-panel-header-title {
  margin-top: 4px;
}
.badge {
  font-weight: 400;
  background-color: $primary;
  line-height: inherit !important;
  margin: 6px;
  color: #fff;
}
.input-low .mat-mdc-form-field-infix  {
  border:0;
  padding-top: 0;
  margin-top: -10px;
}
.mat-option.hide {
  display: none;
}
.children-option {
  margin-left: 15px;
}
.field-picker {
  margin-bottom: 0;
}

mat-footer-cell mat-form-field {
  padding: 10px 0;
}
.none {
  display: none;
}
.language-menu .mat-mdc-select-arrow-wrapper, .language-menu .mdc-line-ripple {
  display: none;
}
.language-menu .mat-mdc-form-field-infix {
  border-top: 0px;
  padding: 5px !important;
}
.language-menu .mat-mdc-select-trigger {
  right: 5px;
}
.language-menu .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

/** POWIADOMIENIA **/
.notification-menu-trigger .mat-badge-content {
  top:0px !important;
  right: -10px !important;
  font-size: 12px;
  background-color: $success;
  font-family: "Montserrat", sans-serif;
}

.notification-circle {
  font-size: 12px;
  margin-right: 18px !important;
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
}
.circle-read {
  color: black !important;
}
.circle-unread {
  color: $success !important;
}
/** -- POWIADOMIENIA **/
// .language-menu .mdc-text-field {
//   padding: 0 5px !important;
// }
