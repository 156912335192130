/*******************
This is for the minisidebar
******************/

.mini-sidebar .top-navbar {
    .navbar-header {
        width: 70px;
        text-align: center;
    }
}


/****************
When click on sidebar toggler and also for tablet
*****************/
.mini-sidebar {
    .navbar-brand span{
        display: none;
    }
}


@media(max-width:1167px) {
    .mini-sidebar {
            .left-sidebar,
            .sidebar-footer {
                left: -240px;
            }
        
    }
}