/*
Template Name: Admin Press Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/

$themecolor: #757780;
$themecolor-dark: #505258;
$topbar: #fff;
$sidebar:#242a33;
$sidebar-text: #687384;
$sidebar-icons: #424a56;
$sidebar-dark: #1c2128;
$sidebar-alt:#1c2128;
$themecolor-alt: #26c6da;
$topbar-alt: #398bf7;
.defaultdark {
/*******************
/*Top bar
*******************/
    .topbar {
        background: $topbar;
        .top-navbar .navbar-header .navbar-brand .light-logo {
            display: none;
        }
        .top-navbar{
            .navbar-nav>.nav-item>span {
                color:$themecolor;
            }
        }
        .navbar-light .navbar-nav .nav-item > {
            a.nav-link {
                color: #a6b7bf;
                &:hover,
                &:focus {
                    color:#242a33!important;
                }
            }
        }

    }
    /*******************
/*General Elements
*******************/
    a.link {
        &:hover,
        &:focus {
            color: $themecolor!important;
        }
    }
    .right-sidebar {
        .rpanel-title {
            background: $themecolor;
        }
    }
    .text-themecolor {
        color: $themecolor!important;
    }
    /*******************
/*Buttons
*******************/
    .btn-themecolor,
    .btn-themecolor.disabled {
        background: $themecolor;
        color: $white;
        border: 1px solid $themecolor;
        &:hover {
            background: $themecolor;
            opacity: 0.7;
            border: 1px solid $themecolor;
        }
        &.active,
        &:focus {
            background: $themecolor-dark;
        }
    }
/*******************
/*sidebar navigation
*******************/
    .left-sidebar,
    .card-no-border .left-sidebar,
    .card-no-border .sidebar-nav {
        background: $sidebar;
    }
    .user-profile .profile-text a {
        color: $sidebar-text!important;
    }
    .card-no-border .sidebar-footer {
        background: $sidebar-dark;
    }
    .label-themecolor {
        background: $themecolor;
    }

    .sidebar-nav ul li a {
        &.router-link-active,
        &:hover {
            color: $themecolor;
            i {
                color: $themecolor;
            }
        }
    }
    .sidebar-nav ul li.nav-small-cap {
        color: $sidebar-text;
    }
    @media (min-width: 768px) {
    &.horizontal-nav .sidebar-nav #sidebarnav > li > ul {
        background: $sidebar-alt;
    }
    &.horizontal-nav .sidebar-nav #sidebarnav > li:hover > a {
        background: $sidebar-alt;
    }
    &.mini-sidebar .sidebar-nav #sidebarnav {
        > li > ul, > li:hover > a  {
            background: $sidebar-alt;
        }

    }

}
}

